import CacheManager from '@/assets/js/cache/CacheManager'

export default {
    async created() {
		this.$sync.start()

		// Démarrage de l'indexation
		const cacheManager = CacheManager.inst()
		await cacheManager.init()
		cacheManager.cacheAdditionalColumns()
    }
}
