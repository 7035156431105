<template>
    <div
        class="d-flex align-items-center"
        @click="toggleSync"
    >
        <font-awesome-icon
            v-if="progress === progressStates.COMPLETED"
            :style="{
                color: stateColor
            }"
            class="mr-2"
            :icon="['fas', 'circle']"
        />
        <font-awesome-icon
            v-else
            :style="{
                color: stateColor
            }"
            class="mr-2"
            :icon="['fas', 'sync-alt']"
            spin
        />
		<span>
			{{ $t(`storage.sync_states.${state}`) }}
        </span>
        <span
            v-if="state !== syncStates.STOPPED"
            v-b-tooltip.hover
            :title="$t('storage.pause_sync')"
            class="ml-auto"
        >
            <font-awesome-icon :icon="['fal', 'pause']"/>
        </span>
        <span
            v-else
            v-b-tooltip.hover
            :title="$t('storage.resume_sync')"
            class="ml-auto"
        >
            <font-awesome-icon :icon="['fal', 'play']"/>
        </span>
        <span
            @click.stop="forceSync"
            class="ml-auto"
            v-b-tooltip.hover
            :title="$t('storage.force_sync')"
        >
            <font-awesome-icon :icon="['fal', 'sync-alt']"/>
        </span>
    </div>
</template>

<script>
import Storage from  "@/mixins/Storage.js"
import { SYNC_STATES, PROGRESS_STATES } from  "@/assets/js/sync/states"

export default {
    name: 'Synchronizer',
    mixins: [Storage],
    data: () => ({
        stateColors: {
            STARTED: '#2ecc71',
            STOPPED: '#f1c40f',
            ERROR: '#e74c3c'
        }
    }),
    computed: {
        stateColor() {
            return this.stateColors[this.state]
        },
        progressStates() {
            return PROGRESS_STATES
        },
        syncStates() {
            return SYNC_STATES
        },
        state() {
            return this.$store.state.sync.state
        },
        progress() {
            return this.$store.state.sync.progressState
		}
    },
    methods: {
        toggleSync() {
			if (this.$sync.isStarted()) {
				this.$sync.stop()
			}
			else {
				this.$sync.start()
            }
        },
        forceSync() {
            this.$sync.force(true)
        }
    }
}
</script>
